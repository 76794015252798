<template>
    <div class="eror-404__container" v-if="showElement">
        <div class="eror-404__text">Online Exhibition<br>Unavailable</div>
        <div class="eror-404__eror">
            <svg width="872" height="550" viewBox="0 0 872 550" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M172.241 549.473V423.44H0V394.237L129.181 11.5274H232.987V374.256H264.513V423.44H232.987V549.473H172.241ZM172.241 374.256V54.563L63.0525 374.256H172.241Z" fill="#1B1D1C"/>
                <path d="M564.325 172.142V388.858C564.325 453.923 553.816 499.008 532.798 524.112C512.294 548.704 482.562 561 443.602 561C405.156 561 375.424 548.704 354.406 524.112C333.901 499.008 323.649 453.923 323.649 388.858V172.142C323.649 107.077 333.901 62.248 354.406 37.6562C375.424 12.5521 405.156 0 443.602 0C482.562 0 512.294 12.5521 532.798 37.6562C553.816 62.248 564.325 107.077 564.325 172.142ZM503.579 400.385V160.615C503.579 86.8397 483.843 49.9521 444.371 49.9521C425.404 49.9521 410.538 57.8932 399.773 73.7754C389.521 89.6576 384.394 118.604 384.394 160.615V400.385C384.394 442.396 389.521 471.343 399.773 487.225C410.538 503.107 425.404 511.048 444.371 511.048C483.843 511.048 503.579 474.16 503.579 400.385Z" fill="#1B1D1C"/>
                <path d="M779.728 549.473V423.44H607.487V394.237L736.668 11.5274H840.474V374.256H872V423.44H840.474V549.473H779.728ZM779.728 374.256V54.563L670.54 374.256H779.728Z" fill="#1B1D1C"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      showElement: false // Начальное значение - элемент скрыт
    };
  },
  mounted() {
    setTimeout(() => {
      // Через 3 секунды изменяем значение переменной showElement на true,
      // что приведет к отображению элемента
      this.showElement = true;
    }, 3000);
  }
};
</script>