<template>
    <div ref="container"></div>
  </template>
  
  <script>
 import * as THREE from 'three';
 import { USDZLoader } from 'three-usdz-loader';


  
  export default {
    props: {
      data3dImage: Object
    },
    
    data() {
      return {
      //   scene: null,
      // camera: null,
      // renderer: null,
      }
    },
  
    methods: {
        async initThree() {
      // Создание сцены
      this.scene = new THREE.Scene();

      // Создание камеры
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.$refs.container.clientWidth / this.$refs.container.clientHeight,
        0.1,
        1000
      );
      this.camera.position.z = 5;

      // Создание рендерера
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(this.$refs.container.clientWidth, this.$refs.container.clientHeight);
      this.$refs.container.appendChild(this.renderer.domElement);

      // Создание загрузчика и загрузка модели
      const loader = new USDZLoader();
      const group = new THREE.Group();
      this.scene.add(group);

      // Предположим, что у вас есть файл модели в вашем проекте
      const file = this.data3dImage.image_url
    //   require('@/assets/ar/object1.usdz');  // Замените на реальный путь к вашему файлу
      await loader.loadFile(file, group);

      // Добавление света
      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(1, 1, 1).normalize();
      this.scene.add(light);

      // Начало рендера
      this.animate();
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    },
  
    mounted() {
      console.log('Загрузка модели:', this.data3dImage.image_url);



    // this.initThree();
    // window.addEventListener('resize', this.onWindowResize);
    }
  };
  </script>
  
  <style>
  body {
    margin: 0;
    overflow: hidden;
  }
  </style>
  